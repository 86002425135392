<template>
  <div class="xinsheng-warp">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Performance",
};
</script>

<style lang="scss">
.xinsheng-warp {
  .distribution-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}
</style>